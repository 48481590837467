// node_modules
@import "antd/dist/reset.css";
@import "bootstrap/dist/css/bootstrap.min.css";
// files_modules
@import "./fonts.scss";

// variables
:root {
  --primary-font: "Pangram";
  --secondary-font: "Pangram";
  --default-dark: #000;
  --default-light: #fff;
  --default-gray: #eeeeee;
  --primary-color: #000053;
  --secondary-color: #fff;
  --font-base: 1rem;
  --error: #ff0000;
  --success: #5cb85c;
}
// variables

a {
  color: inherit;
  background-color: transparent;
  text-decoration: none;
}

// bootstrap_overwrite

.form-group {
  margin-bottom: 1rem;
}

.label-primary {
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
  color: #808080;
  margin-bottom: 10px;
  &.dark {
    color: #000;
  }
}

.form-control {
  border: 0.0625rem solid #d5dbe0;
  border-radius: 0.1875rem;
  color: #000;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  min-height: calc(1.5em + 12px + 5px);
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &:focus {
    border-color: var(--primary-color);
    box-shadow: none;
  }
}
textarea.form-control {
  padding: 0.9375rem 0.75rem;
  resize: none;
}
select,
select.custom-select {
  appearance: none;
  background: #fff url(../assets/images/select-icon.svg) no-repeat;
  background-position: calc(100% - 15px) center;
  padding-right: 30px;
}
.custom-select-multiple > .ant-select-selector{
  padding: 4.5px;
  border-radius: 3px;
  padding-right: 30px;
  height: 38px !important;
  border: 0.0625rem solid #d5dbe0;
}

.custom-select-multiple > .ant-select-selector > .ant-select-selection-search{
  display: flex;
  align-items: center;
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder,.custom-select-multiple > .ant-select-selector >  .ant-select-selection-item{
  line-height: 38px;
}

.form-control-note {
  font-style: italic;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 16px;
  color: #979797;
  margin-top: 0.3125rem;
}
.form-control-normal-note {
  font-size: 14px;
  line-height: 17px;
  color: #1a1f36;
}
.invalid-feedback {
  display: block;
  font-size: 14px;
  color: var(--error);
  &:empty {
    display: none;
  }
}
.success-feedback {
  display: block;
  font-size: 14px;
  color: var(--success);
  width: 100%;
  margin-top: 0.25rem;
  &:empty {
    display: none;
  }
}

.btn {
  font-weight: 700;
  font-size: 0.875rem;
  padding: 0.4688rem 0.5rem;
  border-radius: 0.1875rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover,
  &:focus {
    box-shadow: none;
    opacity: 0.9;
  }
  &:disabled,
  &.disabled {
    border-color: transparent;
    color: var(--primary-color);
  }
}

.btn-primary {
  color: var(--secondary-color);
  background-color: var(--primary-color);
  border-color: var(--primary-color);

  &:hover,
  &:focus,
  &:active {
    color:var(--secondary-color) !important;
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
  }
}

.card {
  background: #ffffff;
  border: 1px solid #d5dbe0;
  border-radius: 5px;
  margin-bottom: 20px;

  .card-header {
    padding: 12px 25px;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    min-height: 65px;
    border-radius: 6px 6px 0 0;

    .card-title {
      font-size: 22px;
      line-height: 26px;
      color: #000;
      margin: 0;
    }
  }

  .card-body {
    padding: 15px 20px;
  }
}
// bootstrap_overwrite

// antd_overwrite

// Loader
.anticon-spin {
  animation: loadingCircle 0.6s infinite linear;
}

.loader__full {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 10;

  &::after {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  &.loader__cover {
    position: absolute;
  }
}

// Loader
.ant-picker-dropdown {
  font-family: var(--primary-font);
  .ant-picker-today-btn {
    font-weight: 600;
    text-decoration: none;
    color: var(--primary-color);
  }
}
.ant-popover {
  font-family: var(--primary-font);
}
// antd_overwrite

// Base
.cursor {
  cursor: pointer;
}
.underline {
  text-decoration: underline !important;
}

// Slick orverwrite
.slick-arrow {
  z-index: 1;
  &.slick-prev,
  &.slick-next {
    width: 60px;
    height: 60px;
    &::before {
      font-family: inherit;
      font-size: inherit;
      opacity: 1;
    }
  }
  &.slick-prev {
    left: 0;
    background: url("../assets/images/slick-left.svg") no-repeat;
    background-size: cover;
  }
  &.slick-next {
    right: 0;
    background: url("../assets/images/slick-right.svg") no-repeat;
    background-size: cover;
  }
}
// Slick orverwrite

// Custom Checkbox
.custom__checkbox {
  display: inline-block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #808080;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .custom__checkbox--input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

.custom__checkbox--check {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #d5dbe0;
}

.custom__checkbox {
  .custom__checkbox--input:checked ~ .custom__checkbox--check {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
}

.custom__checkbox--check:after {
  content: "";
  position: absolute;
  display: none;
}

.custom__checkbox {
  .custom__checkbox--input:checked ~ .custom__checkbox--check:after {
    display: block;
  }

  .custom__checkbox--check:after {
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

// Custom Checkbox

// Custom Checkbox Only
.custom__checkbox--only {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
  padding-left: 0;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #1a1f36;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 14px;
  height: 14px;

  .custom__checkbox--only-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:disabled {
      pointer-events: none;
      opacity: 0.8;
    }
  }
}

.custom__checkbox--only-check {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #dadbde;
}

.custom__checkbox--only {
  .custom__checkbox--only-input:checked ~ .custom__checkbox--only-check {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
}

.custom__checkbox--only-check:after {
  content: "";
  position: absolute;
  display: none;
}

.custom__checkbox--only {
  .custom__checkbox--only-input:checked ~ .custom__checkbox--only-check:after {
    display: block;
  }

  .custom__checkbox--only-check:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

// Custom Checkbox Only

// Radio
.custom__radio {
  display: inline-block;
  position: relative;
  padding-left: 27px;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1a1f36;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .custom__radio--input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
}

.custom__radio--check {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #dadbde;
  border-radius: 50%;
}

.custom__radio {
  .custom__radio--input:checked ~ .custom__radio--check {
    background-color: #fff;
  }
}

.custom__radio--check:after {
  content: "";
  position: absolute;
  display: none;
}

.custom__radio {
  .custom__radio--input:checked ~ .custom__radio--check:after {
    display: block;
  }
  .custom__radio--input:disabled ~ .custom__radio--check:after {
    display: block;
    background: #ccc;
  }

  .custom__radio--check:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--primary-color);
  }
}

// Radio

// Impersonate
.impersonate {
  background: #f7d9ff;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .impersonate__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .impersonate__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #c800ff;
    text-align: center;
    .impersonate__link {
      color: #c800ff;
      font-weight: 500;
      font-size: 14px;
      background: transparent;
      background-image: none;
      border: 0;
      padding: 0;
      text-decoration: underline;
    }
  }
}
// Impersonate

// Table
.table-responsive-custom {
}
table.table-custom {
  margin-bottom: 0;
  overflow-y: scroll;
  > :not(:first-child) {
    border-top: 0;
  }
  thead {
    border-bottom: 1px solid #e3e8ee;
    th {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #808080;
      text-align: center;
      padding: 0.75rem 0.625rem;
      white-space: nowrap;
      &:first-child {
        text-align: left;
        padding-left: 20px;
      }
    }
  }
  tbody {
    tr {
      &:nth-child(odd) {
        background: #eeeeee;
      }

      td {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.16px;
        color: #000000;
        padding: 0.5rem 0.625rem;
        vertical-align: middle;
        white-space: nowrap;
        text-align: center;
        &:first-child {
          text-align: left;
          padding-left: 20px;
        }
        .table-form-control {
          min-width: 80px;
          max-width: 80px;
          display: inline-block;
        }
      }
    }
  }
  .table-selection-column {
    width: 20px;
  }
  .table-cell-first {
    width: 100%;
  }

  .table-cell-last {
    min-width: 120px;
  }
}
.table-blank {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  .table-blank-text {
    color: #697386;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
}
// Table

// Table Pagination
.table__pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 25px 0;
  .table__pagination--results {
  }
  .table__pagination--nav {
    .table__pagination--prev,
    .table__pagination--next {
      margin-left: 8px;
    }
  }
}
// Table Pagination

// Base

// React Modal Css
.ReactModal__Body--open {
  overflow: hidden;
  // padding-right: 17px;
}

.ReactModal__Body--open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open.modal {
  background: rgba(0, 0, 0, 0.5);
}

.ReactModal__Overlay--after-open.modal {
  display: block;
}

.react-modal {
  .modal-content {
    border: 0;
    border-radius: 10px;
    &.modal-content-flat {
      border-radius: 0;
    }
  }

  .modal-dialog {
    max-width: 600px;

    @media (min-width: 576px) {
      margin: 1rem auto;
    }

    &.react-modal-dialog-xs {
      max-width: 420px;
    }

    &.react-modal-dialog-sm {
      max-width: 700px;
    }

    &.react-modal-dialog-sm-md {
      max-width: 850px;
    }

    &.react-modal-dialog-md {
      max-width: 960px;
    }

    &.react-modal-dialog-lg {
      max-width: 1024px;
    }

    &.react-modal-dialog-xl {
      max-width: 1200px;
    }
  }
}

.react-modal-header {
  padding: 20px;
  position: relative;

  .react-modal-title {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;
  }

  .react-modal-subtitle {
    font-size: 16px;
    line-height: 25px;
    color: #000;
    margin-bottom: 0;
  }

  .react-modal-close {
    position: absolute;
    top: 8px;
    right: 15px;
    z-index: 1;
  }
}

.react-modal-body {
  padding: 20px;
}
.react-modal-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}
.react-modal-body-text {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.react-modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  border-radius: 0 0 10px 10px;
}

// React Modal Css

// material-Lable style
.material-textfield {
  position: relative;

  .material-textfield-label {
    font-size: 14px;
    color: #000000;
    position: absolute;
    pointer-events: none;
    left: 14px;
    top: 16px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    margin-bottom: 0;
  }

  .material-textfield--input {
    &:focus,
    // &:read-only,
    &:disabled,
    &:valid {
      padding-top: 20px;
    }

    // &:read-only ~ .material-textfield-label,
    &:disabled ~ .material-textfield-label,
    &:valid ~ .material-textfield-label,
    &:focus ~ .material-textfield-label {
      top: 8px;
      left: 14px;

      font-size: 12px;
      color: #000;
    }

    &:disabled ~ .material-textfield-label {
      color: #9b9b9b;
      top: 8px;
      font-size: 14px;
      font-weight: 700;
    }
  }
}

// material-Lable style

// Base

.form-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.16px;
  color: #000000;
  margin-bottom: 15px;
}
.form-required-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  letter-spacing: -0.16px;
  color: #808080;
}
.form-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  .btn {
    min-width: 190px;
  }
}
.addtocart-container{
  justify-content: flex-end;
  flex-direction: row;
}
// Base

.form-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;
  .form-header-col {
    display: flex;
    align-items: flex-start;
    &:last-child {
      align-items: center;
    }
    .form-header-col-inner {
      width: 100%;
    }
  }
  .form-header-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.16px;
    color: #000000;
    margin-bottom: 10px;
  }
  .form-header-subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.16px;
    color: #808080;
    margin-bottom: 0;
  }
}
.icon-left {
  margin-right: 10px;
}
.icon-right {
  margin-left: 10px;
}
.btn-edit {
  padding: 2px;
  color: var(--primary-color);
  &:hover,
  &:focus {
    color: var(--primary-color);
    border-color: #eeeeee;
    background-color: #eeeeee;
  }
}

.btn-edit-without-bg {
  padding: 2px;
  color: var(--primary-color);
  &:hover,
  &:focus {
    color: var(--primary-color);
    border: none;
    outline: none;
  }
}

.product-grid {
  .product-grid-item {
    margin-bottom: 10px;
  }
}
.product-card {
  background: var(--default-gray);
  border: 1px solid #d5dbe0;
  border-radius: 5px;
  padding: 30px 40px;
  // &:hover {
  //   background: #EEEEEE;
  // }
  .product-thumb {
    width: 100%;
    height: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .product-info {
    // margin-left: 15px;
  }
  .product-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.16px;
    color: #000000;
    margin-bottom: 5px;
  }
  .product-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.16px;
    color: #000000;
    margin-bottom: 20px;
  }
  .btn-more-info {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: var(--primary-color);
    padding: 0.4688rem 0;
    .icon {
      margin-left: 10px;
    }
  }
}
.product-card-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .product-card-col {
    display: flex;
    align-items: center;
  }
}
.product-more-info {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  .product-price {
    font-weight: 700;
    font-size: 32px;
    line-height: 28px;
    text-align: right;
    letter-spacing: -0.16px;
    color: #000000;
    margin-bottom: 10px;
  }
  .btn-select {
    min-width: 120px;
  }
  .product-fees-info {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    margin-top: 10px;
    color: #808080;
  }
}

.additional-details-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.16px;
  color: #000000;
  margin-bottom: 10px;
}
.additional-details-card {
  border: 1px solid #d5dbe0;
  border-top: 0 solid #d5dbe0;
  padding: 20px 40px;
}

.additional-fees-list {
  list-style: none;
  padding: 0;
  margin: 0;
  > li {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #000000;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      &:first-child {
        width: 100%;
        display: flex;
        align-items: center;
        white-space: nowrap;
        &::after {
          content: "";
          width: 100%;
          height: 1px;
          background-color: #d5dbe0;
          display: inline-block;
          margin: 0 10px;
        }
      }
      &:last-child {
        font-weight: 700;
        white-space: nowrap;
        text-align: right;
      }
    }
  }
}

.prohibited-items-list {
  list-style: disc !important;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap-reverse;
  > li {
    vertical-align: top;
    width: calc(100% / 2);
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #000000;
    position: relative;
  }
}

.prohibited-card {
  background: #ffffff;
  border: 1px solid #d5dbe0;
  border-radius: 5px;
  width: 100%;
  min-height: 350px;
  .prohibited-card-header {
    background: #eeeeee;
    border-bottom: 1px solid #d5dbe0;
    border-radius: 5px 5px 0 0;
    padding: 30px 20px;
    .prohibited-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: -0.16px;
      color: #000000;
      margin-bottom: 10px;
    }
    .prohibited-subtitle {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.16px;
      color: #808080;
      margin-bottom: 0;
    }
  }
  .prohibited-item-list {
    list-style: disc;
    padding: 38px;
    margin: 0;
    display: flex;
    flex-wrap: wrap-reverse;
    > li {
      width: 49%;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.16px;
      color: #000000;
    }
  }
}

.step-progressbar {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  // counter-reset: step;
  width: 100%;
  max-width: 860px;

  > li {
    position: relative;
    // display: inline-flex;
    // align-items: center;
    width: calc(100% / 3);

    &:before {
      content: "";
      // content: counter(step);
      // counter-increment: step;
      background: transparent url(../assets/images/inactive.svg) no-repeat;
      background-position: center;
      width: 20px;
      height: 20px;
      display: block;
      text-align: center;
      margin: 0 auto;
      border-radius: 50%;
      // background-color: var(--primary-color);
    }

    &:after {
      content: "";
      position: absolute;
      width: calc(100% - 40px);
      height: 3px;
      background: #d5dbe0;
      border-radius: 10px;
      top: 10px;
      left: calc(-50% + 20px);
      z-index: -1;
    }

    &:first-child:after {
      content: none;
    }

    &.active {
      &:before {
        background: transparent url(../assets/images/active.svg) no-repeat;
        background-position: center;
      }

      + li:after {
        background: var(--primary-color);
      }
    }
  }
}

.step-progressbar-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 15px;
}
.step-progress-label {
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #000000;
  margin-bottom: 2px;
}
.step-progress-order-date {
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #808080;
}

// Cart
.cart {
}
.cart-header {
  margin-bottom: 20px;
  .cart-header-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.16px;
    color: #000000;
  }
}
.cart-body {
  .prodcut-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.16px;
    color: #000000;
    border-bottom: 1px solid #d5dbe0;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
}
.cart-footer {
  .cart-note {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.16px;
    color: #808080;
    margin-top: 10px;
  }
}

.navcart-popover {
}
.navcart-popover-overlay {
  .ant-popover-content {
    width: 100%;
    max-width: 380px;
    .ant-popover-inner {
      padding: 30px;
    }
  }
}
// Cart
.btn-primary:disabled{
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border-color: var(--primary-color);
}

.PhoneInputCountry{
  display: none !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: var(--primary-color) !important;
  color: white;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: var(--primary-color) !important;
}

/* dropdown */
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: var(--primary-color) !important;
}

.ant-select-focused .ant-select-selector {
  border-color: var(--primary-color) !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: var(--primary-color);
}




//media query
@media screen and (max-width: 768px) {
  .product-card-row {
    .product-card-col {
      margin-top: 25px;
    }
  }
  .prohibited{
    margin-top: 20px;
  }
  .prohibited-items-list {
    > li {
      width: 50%;
    }
  }
  .form-header-title,.form-header-subtitle {
   text-align: center;
  }
  .btn-edit-without-bg{
    margin: auto;
  }
  .addtocart-container{
    justify-content: center;
  }
}

//media query
@media screen and (max-width: 450px) {
  .product-card {
    padding: 20px 10px;
    .product-title {
      font-size: 16px;
      line-height: 20px;
    }
  }
  .product-card-row {
    .product-card-col {
      margin-top: 25px;
    }
  }
  .product-more-info {
    .product-price {
      font-size: 26px;
    }
  }
  .form-header{
    display: block;
  }
  .table-container{
    max-width: 100% !important;
    overflow-x: scroll !important;
  }
  .prohibited-card{
    margin-top: 15px;
  }
  .additional-details-card{
    padding: 20px 15px;
  }
  .btn-more-info {
    font-size: 13px !important;
    .icon {
      margin-left: 5px !important;
    }
  }
}
