.Subtitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.16px;
  color: #000000;
  margin-bottom: 5px;
}
.OrderTime {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.16px;
  color: #000000;
  margin-bottom: 10px;
}
.Address {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.16px;
  color: #000000;
}
.ProductTitle {
  border-top: 1px solid #d5dbe0;
  border-bottom: 1px solid #d5dbe0;
  padding: 15px 20px;
}
.ProductTitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.16px;
  color: #000000;
}
.BorderClass {
  border-top: 1px solid #d5dbe0;
  margin-top: 10px !important;
}

.ProductItemList {
  list-style: none;
  padding: 0;
  margin: 0;
}
.ProductItemList > li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.16px;
  color: #000000;
  padding: 2px 0;
}
.ProductItemList > li span:last-child {
  font-weight: 500;
  text-align: right;
}
.ButtonTaxFees {
  padding: 0;
  font-weight: 700;
  color: #01aef0;
  border: 0;
}
.ButtonTaxFees:hover,
.ButtonTaxFees:focus {
  color: #01aef0;
}
.ButtonTaxFees .Icon {
  margin-left: 10px;
}

.ProductSummaryFooter {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 0;
  border-top: 1px solid #d5dbe0;
}
.ProductSummaryLabel {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: #000000;
}
.ProductSummaryAmount {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  letter-spacing: -0.16px;
  color: #000000;
}

.PaymentInformation {
}
.Spacer {
  margin-bottom: 10px;
}
.CvcNumberInfo {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-decoration: underline;
  color: var(--primary-color);
}
.SecureInfo {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #808080;
  margin-bottom: 30px;
}
.SecureInfo .Icon {
  margin-right: 5px;
}
.InfoBtn {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary-color) !important;
  padding: 0.4688rem 0;
  border: none !important;
  outline: none !important;
}
.InfoBtn:hover,
.InfoBtn:active,
.InfoBtn:focus {
  border: none !important;
  outline: none !important;
  color: var(--primary-color) !important;
}
.Icon {
  margin-left: 10px;
}
