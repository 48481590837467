.SectionDefault {
  background: #f4f7fc;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
   background-image: url("../../../assets/images/home-bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.DefaultTitle {
}
.Container {
  width: 90%;
}
.HomeHeadingTitle {
  font-weight: 900;
  font-size: 54px;
  line-height: 55px;
  color: #fff;
}
.HomeDescription {
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  color: #fff;
  margin-top: 10px;
}

.CardWrapper {
  background: #ffffff;
  padding: 25px !important;
}
.HeadWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.HeadWrapperTitle {
  font-weight: 900;
  font-size: 28px;
  line-height: 33.89px;
  text-align: center;
  letter-spacing: 0.2px;
}
.HeadWrapperInner {
}
.HeadWrapperInnerWrap {
}
.HeadWrapperFormGrid {
}
.SubmitContainer {
  text-align: center;
}

.FormGridCol {
  flex: 1;
}
.SubmitButton {
  min-width: 146px;
  min-height: 38px;
  margin-top: 18px;
  max-height: 38px;
  background-color: var(--primary-color) !important;
  color: var(--secondary-color) !important;
  border-color: var(--primary-color) !important;
}

.SkeletonBottom {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 160px;
}
.HomeScreenInputContainer {
  margin-top: 12px;
}

@media only screen and (min-width: 1025px) {
  .Container {
    width: 80%;
  }
}

@media only screen and (max-width: 575px) {
  .HeadingWrapper {
    margin-top: 30px !important;
  }
  .CardWrapper {
    margin: 30px 0 !important;
  }
}

@media only screen and (max-width: 450px) {
  .SectionDefault {
    background-size: auto 100%;
  }
  .HomeScreenInputContainer {
    width: 100%;
  }
  .HomeHeadingTitle {
    font-weight: 800;
    font-size: 40px;
    line-height: 42px;
  }
}
