@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.appLoadingSpinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid var(--primary-color); /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.appSpinnerContainer {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.submitLoadingSpinner {
    width: 22px;
    height: 22px;
    border: 2px solid #f3f3f3; /* Light grey */
    border-top: 5px solid var(--primary-color); /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.submitSpinnerContainer {
    display: grid;
    justify-content: center;
    align-items: center;
}