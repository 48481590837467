.SectionDefault {
  background: #f4f7fc;
  min-height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.DefaultTitle {
}
.CardWrapper {
  width: 100%;
  max-width: 890px;
  margin: -100px auto;
}
.HeadWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background: var(--primary-color);
}
.HeadWrapperTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #ffffff;
}
.HeadWrapperInner {
  background: #ffffff;
  border-color: #d5dbe0;
  border-style: solid;
  border-width: 0 1px 1px;
}
.HeadWrapperInnerWrap {
  padding: 30px 40px;
}
.HeadWrapperFormGrid {
  display: flex;
  gap: 20px;
}
.FormGridCol {
  flex: 1;
}
.SubmitButton {
  min-width: 146px;
  min-height: 38px;
  margin-top: 30px;
  max-height: 38px;
}

.SkeletonBottom {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 160px;
}

.Terms div, .Terms li{
  color: #808080;
}
