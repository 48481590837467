.ProductThumb {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .ProductThumb > img,
  .ProductThumb > video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }
  .ProductTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.16px;
    color: #000000;
    margin-bottom: 10px;
  }
  .ProductOverviewList {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .ProductOverviewList > li,.checkbox-options > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.16px;
    color: #000000;
  }
  
  .ProductNote {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    background: #eeeeee;
    border-top: 1px solid #d5dbe0;
    padding: 15px 25px;
  }
  .ProductNote > span {
    font-weight: 400;
  }

  .contactDetail {
    margin-top: 5px;
  }
  