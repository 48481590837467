.Nav {
  display: flex;
  align-items: center;
  background: #eeeeee;
  width: 100%;
  min-height: 60px;
  margin-bottom: 30px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  margin-top: 10px;
}
.NavLink {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #000;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 6px 8px;
  border-radius: 0;
  border: 0;
  width: 100%;
  position: relative;
  min-height: 60px;
}
/* .NavLink:not(:last-child)::after {
    content: '';
    width: 0;
    height: 0;
    border-width: 29.5px 0 29.5px 23px;
    border-color: transparent transparent transparent currentColor;
    border-style: solid;
  } */
.NavLink.Active:hover,
.NavLink.Active:focus {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  position: relative;
}

.NavLink.Fill:hover,
.NavLink.Fill:focus {
  color: var(--secondary-color) !important;
  background-color: var(--primary-color) !important;
  position: relative;
  z-index: 99;
  opacity: 1;
}

.NavLink:disabled,
.NavLink:disabled {
  pointer-events: none;
}
.NavLink.Fill {
  color: var(--secondary-color);
  background-color: var(--primary-color);
}
.NavLink.Active {
  color: #fff;
  background-color: var(--primary-color);
}
/* .NavLink.Active::before, */
.NavLink.Active::after,
.NavLink.Fill::after {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 0 30px 12px;
}

/* .NavLink.Active::before {
  left: 0;
  border-color: transparent transparent transparent var(--primary-color);
} */
.NavLink.Active::after {
  right: -12px;
  border-color: transparent transparent transparent var(--primary-color);
}
.NavLink.Fill::after {
  right: -12px;
  border-color: transparent transparent transparent var(--primary-color);
  z-index: 99;
}

.NavCart {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-color);
  padding: 6px 20px;
  width: 100%;
  min-height: 60px;
  user-select: none;
  cursor: pointer;
}
.NavCartLabel {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: var(--default-light);
}
.NavCartPrice {
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  text-align: right;
  letter-spacing: -0.16px;
  color:var(--default-light);
  display: flex;
  align-items: center;
  padding-left: 30px;
}
.NavCartTotalPrice {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
}
.NavCartPriceFees {
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-align: right;
  color:var(--default-light);
}
.Icon {
  margin-left: 10px;
}

/* Cart Css */
.ProductItemList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ProductItemList > li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.16px;
  color: #000000;
  padding: 2px 0;
}

.ProductItemList > li span:last-child {
  font-weight: 500;
  text-align: right;
}

.ButtonTaxFees {
  padding: 0;
  font-weight: 700;
  color: var(--primary-color);
}

.ButtonTaxFees .Icon {
  margin-left: 10px;
}

.ProductSummaryFooter {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 0;
  margin-top: 10px;
  border-top: 1px solid #d5dbe0;
}

.ProductSummaryLabel {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: #000000;
}

.ProductSummaryAmount {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  letter-spacing: -0.16px;
  color: #000000;
}
.MobileContent,
.MobileContentHeader {
  display: none;
}
/* Cart Css */

@media only screen and (max-width: 768px) {
  .Nav {
    flex-wrap: wrap;
    margin-top: 0;
  }
  .MobileContent {
    display: block;
    border-bottom: 1px solid #d5dbe0;
  }
  .MobileContentHeader {
    display: flex !important;
    min-height: 50px;
    width: 50%;
  }
  .NavCart {
    align-items: initial;
  }
  .NavCartPriceFees {
    width: 120px;
  }
  .NavCartPrice {
    padding-left: 0;
  }

  .CartPopup {
    width: 50%;
    min-height: 50px;
  }
  .NavLink {
    display: none !important;
  }
  .NavLinkMob {
    font-weight: 900;
    font-size: 13px;
    line-height: 14px;
    width: 14px;
    height: 14px;
    border-radius: 100px !important;
    color: white !important;
    text-align: center;
    position: relative;
  }
  .NavLinkMob.Active {
    background-color: black;
  }
  .NavLinkMob.Fill {
    background-color: #fff;
  }
  .NavLinkMob.Disabled {
    background-color: #808080 !important;
    pointer-events: none;
  }
  .NavLinkMobLine {
    width: 80px;
    height: 0;
    border: 0.5px solid #808080 !important;
  }
  .NavLinkMobLine.Fill {
    border-color: var(--primary-color) !important;
  }
  .LineContainer {
    padding: 0 6rem;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 50px;
    align-items: center;
    z-index: -2;
  }
  .BreadCrumbTextMob {
    color: #000;
    position: absolute;
    bottom: -15px;
    width: 200px;
    font-size: 8px;
    font-weight: 700;
  }
  .NavCartTotalPrice {
    font-size: 16px;
  }
  .NavCartLabel {
    font-size: 10px;
    margin-top: 5px;
  }
}
.InfoBtn {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary-color) !important;
  padding: 0.4688rem 0;
  border: none !important;
  outline: none !important;
}
.InfoBtn:hover,
.InfoBtn:active,
.InfoBtn:focus {
  border: none !important;
  outline: none !important;
  color: var(--primary-color) !important;
}

@media only screen and (max-width: 525px) {
  .LineContainer {
    padding: 0 3rem;
  }
  .NavLinkMobLine {
    width: 35px;
  }
}
